import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FaEnvelope, FaFacebook, FaInstagram, FaMapMarkedAlt, FaPhoneAlt, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import Seo from '../components/Seo'
import { CheckboxInput, RadioInput, SelectInput, Textarea, TextInput } from '../components/FormikInputs'

import * as styles from '../styles/pages/contact.module.scss'

const formInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  cellphone: '',
  // learningMethods: [],
  learningMethod: '',
  studyField: '',
  course: '',
  message: '',
  receiveUpdates: true
}

const formValidationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email().required("Email is required"),
  cellphone: Yup.string().required("Cellphone is required"),
  learningMethod: Yup.string(),
  studyField: Yup.string(),
  course: Yup.string(),
  message: Yup.string(),
  receiveUpdates: Yup.boolean()
})

const ContactPage = ({ data }) => {
  const { socialMediaLinks, ...contactInfo } = data.sanityCompanyInfo
  const studyFields = data.allSanityStudyField.nodes
  const courses = data.allSanityCourse.nodes
  const [ success, setSuccess ] = useState(false)

  const studyFieldOptionElements = studyFields.map(field => (
    <option key={field.title} value={field.title}>{field.title}</option>
  ))

  const courseOptionElements = (studyField) => {
    const filteredCourses = courses.filter(course => {
      const courseStudyFields = course.studyFields.map(field => field.title)
      return courseStudyFields.includes(studyField)
    })

    const filteredCourseOptionElements = filteredCourses.map(course => (
      <option key={course.title} value={course.title}>{course.title}</option>
    ))

    return (<>
      <option value=''>~ Choose a course ~</option>
      {filteredCourseOptionElements}
    </>)
  }

  const formSubmitHandler = async (values, { resetForm }) => {
    try {
      // SEND EMAIl
        // to Mass College
        const response = await fetch('/api/contactEmail', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ values })
          })
        const responseData = await response.json();    
        console.log(responseData)

        setSuccess(true)

    } catch(error) {
      console.log(error)
      alert("Sorry, something went wrong. Please try again.")
    }
  }

  return (
    <main className={styles.contactPage}>
      <Seo title="Contact Us" pathname='/contact' />
      {/* Note: The .page-container class is on this div and not on the main element because of the map below. */}
      <div className="page-container">
        <h1>Contact Us</h1>
        <p>Complete the enquiry form below and a Student Adviser will contact you shortly!</p>

        <section className={styles.pageContent}>
        
          {success ? (
            <div className="flex flex-col items-center lg:h-full justify-center">
              <h2 className="text-2xl lg:text-4xl font-bold text-gray-900 mt-4">
                Thank you!
              </h2>
              <p className="text-gray-600 mt-1 lg:mt-2 text-lg">
                We&apos;ll be in touch real soon
              </p>
            </div>
          ) : (
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {({ isSubmitting, values }) => (
                <Form
                  name="contact"
                  className={`${styles.contactForm} form-primary`}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <TextInput
                    label="First Name"
                    name="firstName"
                    type="text"
                  />
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    type="text"
                  />
                  <TextInput
                    label="Email"
                    name="email"
                    type="email"
                  />
                  <TextInput
                    label="Cellphone"
                    name="cellphone"
                    type="tel"
                  />

                  <fieldset>
                    <legend>What learning method are you interested in?</legend>
                    <RadioInput name="learningMethod" value="Full Time">
                      Full Time
                    </RadioInput>
                    <RadioInput name="learningMethod" value="Part Time">
                      Part Time
                    </RadioInput>
                    <RadioInput name="learningMethod" value="Online">
                      Online
                    </RadioInput>
                    <RadioInput name="learningMethod" value="Workshops">
                      Workshops
                    </RadioInput>
                  </fieldset>

                  <fieldset>
                    <legend>What course/study field are you interested in?</legend>
                    <SelectInput label="Study Field" name="studyField">
                      <option value="">~ Choose a study field ~</option>
                      {studyFieldOptionElements}
                    </SelectInput>
                    <SelectInput label="Course" name="course">
                      {!values.studyField 
                        ? <option value=''>~ Please choose a study field first ~</option>
                        : courseOptionElements(values.studyField)
                      }
                    </SelectInput>
                  </fieldset>

                  <Textarea label="Message" name="message" className="c-span-full" rows="10" />

                  <CheckboxInput name="receiveUpdates" className="c-span-full">
                    I would like to receive updates on course start dates and new course information
                  </CheckboxInput>

                  <span className="c-span-full">
                    <button type="submit" disabled={isSubmitting}>Submit</button>
                  </span>
                </Form>
              )}
            </Formik>
          )}

          <div>
            <div className={styles.contactInfo}>
              <ul id='contact-info-list'>
                <li>
                  <FaMapMarkedAlt />
                  <a href='https://www.google.com/maps/place/Mass+Computer+Training+%26+Printers/@-22.932497,30.4293371,14z/data=!4m10!1m2!2m1!1smass+computer+training+fet+college!3m6!1s0x1ec5c6ceffffffff:0xabafecfeb16919a!8m2!3d-22.932497!4d30.4674459!15sCiJtYXNzIGNvbXB1dGVyIHRyYWluaW5nIGZldCBjb2xsZWdlWiQiIm1hc3MgY29tcHV0ZXIgdHJhaW5pbmcgZmV0IGNvbGxlZ2WSAQ1pbnRlcm5ldF9jYWZl4AEA!16s%2Fg%2F11b6dq830k?entry=ttu' target='_blank' rel='noreferrer'>
                    <span dangerouslySetInnerHTML={{__html: contactInfo.address.replaceAll('\n', '<br>')}} />
                  </a>
                </li>
                <li>
                  <FaEnvelope />
                  <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
                </li>
                <li>
                  <FaPhoneAlt />
                  <a href={`tel:${contactInfo.telephoneNumber.replaceAll(' ', '')}`}>{contactInfo.telephoneNumber}</a>
                </li>
                <li>
                  <FaWhatsapp />
                  <a href={`https://wa.me/+27${contactInfo.whatsappNumber.replaceAll(' ', '')}`} target='_blank' rel='noreferrer'>{contactInfo.whatsappNumber}</a>
                </li>
              </ul>

              <div className={styles.socialLinks}>
                <a href={socialMediaLinks.facebook} title='Facebook' aria-label='Facebook' target='_blank' rel='noreferrer'>
                  <FaFacebook />
                </a>
                <a href={socialMediaLinks.instagram} title='Instagram' aria-label='Instagram' target='_blank' rel='noreferrer'>
                  <FaInstagram />
                </a>
                <a href={socialMediaLinks.youtube} title='YouTube' aria-label='YouTube' target='_blank' rel='noreferrer'>
                  <FaYoutube />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* --- MAP --- */}
      {/* <iframe 
        className={styles.map} 
        title="google-map" 
        src='https://www.google.com/maps/place/Mass+Computer+Training+%26+Printers/@-22.932497,30.4293371,14z/data=!4m10!1m2!2m1!1smass+computer+training+fet+college!3m6!1s0x1ec5c6ceffffffff:0xabafecfeb16919a!8m2!3d-22.932497!4d30.4674459!15sCiJtYXNzIGNvbXB1dGVyIHRyYWluaW5nIGZldCBjb2xsZWdlWiQiIm1hc3MgY29tcHV0ZXIgdHJhaW5pbmcgZmV0IGNvbGxlZ2WSAQ1pbnRlcm5ldF9jYWZl4AEA!16s%2Fg%2F11b6dq830k?entry=ttu'

        loading="lazy" 
      /> */}
    </main>
  )
}

export const query = graphql`
  query TestContactPageQuery {
    sanityCompanyInfo {
      address
      email
      telephoneNumber
      whatsappNumber
      socialMediaLinks {
        facebook
        instagram
        youtube
      }
    }
    allSanityStudyField(sort: {fields: title}) {
      nodes {
        title
      }
    }
    allSanityCourse(sort: {fields: title}) {
      nodes {
        title
        studyFields {
          title
        }
      }
    }
  }
`

export default ContactPage